import { GroupProps, MeshProps, useFrame, useThree } from "@react-three/fiber";
import { Html, Line } from "@react-three/drei";
import { OrbitControls, TransformControls } from "three-stdlib";
import {
  MouseEventHandler,
  ChangeEventHandler,
  useCallback,
  useReducer,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Mesh,
  Vector3,
  CatmullRomCurve3,
  Group,
  PerspectiveCamera,
} from "three";
import { ControlPoint } from "./types";

type V = [number, number, number];

type SplineEditorState = {
  controlPoints: ControlPoint[];
  selectedControlPoint: number | null;
};

type SplineEditorActions = {
  moveControlPoint: { i: number; point: V };
  rollControlPoint: { i: number; roll: number };
  selectControlPoint: { i: number };
  addControlPoint: {};
  removeLastControlPoint: {};
};

function splineEditorReducer<A extends keyof SplineEditorActions>(
  state: SplineEditorState,
  action: { type: A; payload: SplineEditorActions[A] }
) {
  const actionHandlers: {
    [Key in keyof SplineEditorActions]: (
      payload: SplineEditorActions[Key]
    ) => SplineEditorState;
  } = {
    addControlPoint() {
      const lastControlPointPosVec3 = new Vector3(
        ...state.controlPoints[state.controlPoints.length - 1].position
      );
      const preLastControlPointPosVec3 = new Vector3(
        ...state.controlPoints[state.controlPoints.length - 2].position
      );
      const dir = lastControlPointPosVec3
        .clone()
        .sub(preLastControlPointPosVec3)
        .normalize();
      return {
        ...state,
        controlPoints: [
          ...state.controlPoints,
          {
            position: lastControlPointPosVec3.add(dir.addScalar(0.1)).toArray(),
            roll: 0,
          },
        ],
        selectedControlPoint: state.controlPoints.length,
      };
    },
    removeLastControlPoint() {
      return {
        ...state,
        controlPoints: state.controlPoints.slice(0, -1),
      };
    },
    moveControlPoint({ i, point }: SplineEditorActions["moveControlPoint"]) {
      return {
        ...state,
        controlPoints: [
          ...state.controlPoints.slice(0, i),
          { ...state.controlPoints[i], position: point },
          ...state.controlPoints.slice(i + 1),
        ],
      };
    },
    rollControlPoint({ i, roll }: SplineEditorActions["rollControlPoint"]) {
      return {
        ...state,
        controlPoints: [
          ...state.controlPoints.slice(0, i),
          { ...state.controlPoints[i], roll },
          ...state.controlPoints.slice(i + 1),
        ],
      };
    },
    selectControlPoint({
      i,
    }: SplineEditorActions["selectControlPoint"]): SplineEditorState {
      return {
        ...state,
        selectedControlPoint: i,
      };
    },
  };

  return actionHandlers[action.type](action.payload);
}

function getSpineEditorInitialState(): SplineEditorState {
  const controlPointsString = window.localStorage.getItem("controlPoints");

  return {
    controlPoints: controlPointsString
      ? JSON.parse(controlPointsString)
      : [
          { position: [0, 0, 0], roll: 0 },
          { position: [0, 0, 1], roll: 0 },
        ],
    selectedControlPoint: null,
  };
}

type SplineEditroProps = {
  onCurveChange?: (curve: CatmullRomCurve3) => void;
  onControlPointsChange?: (controlPoints: ControlPoint[]) => void;
};

function SplineEditor({
  onCurveChange,
  onControlPointsChange,
}: SplineEditroProps) {
  const [{ controlPoints, selectedControlPoint }, dispatch] = useReducer(
    splineEditorReducer,
    null,
    getSpineEditorInitialState
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.localStorage.setItem(
        "controlPoints",
        JSON.stringify(controlPoints)
      );
    }, 250);
    return () => clearTimeout(timeout);
  }, [controlPoints]);

  const curve = useMemo(() => {
    return new CatmullRomCurve3(
      controlPoints.map((point) => new Vector3(...point.position))
    );
  }, [controlPoints]);

  useEffect(() => {
    if (onCurveChange) {
      onCurveChange(curve);
    }
  }, [curve]);

  useEffect(() => {
    if (onControlPointsChange) {
      onControlPointsChange(controlPoints);
    }
  }, [controlPoints]);

  const curvePoints = useMemo(() => {
    return curve.getPoints(256);
  }, [curve]);

  const lastControlPoint = controlPoints[controlPoints.length - 1];
  return (
    <>
      <Line points={curvePoints} color={0xff0000} />
      {controlPoints.map((point, i) => (
        <SplinePoint
          position={point.position}
          key={i}
          controlsEnabled={selectedControlPoint === i}
          onClick={() =>
            dispatch({ type: "selectControlPoint", payload: { i } })
          }
          onPositionChange={(point) => {
            dispatch({ type: "moveControlPoint", payload: { i, point } });
          }}
        />
      ))}
      {selectedControlPoint !== null && controlPoints[selectedControlPoint] && (
        <ChangeRoll
          position={controlPoints[selectedControlPoint].position}
          value={controlPoints[selectedControlPoint].roll}
          onChange={(event) => {
            dispatch({
              type: "rollControlPoint",
              payload: {
                i: selectedControlPoint,
                roll: Number(event.target.value),
              },
            });
          }}
        />
      )}
      {selectedControlPoint === controlPoints.length - 1 && (
        <>
          <AddControlPoint
            position={lastControlPoint.position}
            onClick={() => {
              dispatch({ type: "addControlPoint", payload: {} });
            }}
          />
          {controlPoints.length > 2 && (
            <RemoveControlPoint
              position={lastControlPoint.position}
              onClick={() => {
                dispatch({ type: "removeLastControlPoint", payload: {} });
              }}
            />
          )}
        </>
      )}
    </>
  );
}

function isEnablable(value: unknown): value is { enabled: boolean } {
  return (
    typeof value === "object" &&
    value !== null &&
    Object.hasOwn(value, "enabled")
  );
}

type SplinePointProps = MeshProps & {
  controlsEnabled: boolean;
  onPositionChange: (position: V) => void;
};

function SplinePoint({
  controlsEnabled,
  onPositionChange,
  ...meshProps
}: SplinePointProps) {
  const scene = useThree((state) => state.scene);
  const defaultCamera = useThree((state) => state.camera);
  const defaultControls = useThree((state) => state.controls);
  const gl = useThree((state) => state.gl);
  const [mesh, setMesh] = useState<Mesh>();

  const refCallback = useCallback((mesh: Mesh) => {
    setMesh(mesh);
  }, []);

  useEffect(() => {
    if (!controlsEnabled || !mesh) {
      return;
    }
    const controls = new TransformControls(defaultCamera, gl.domElement);
    controls.attach(mesh);
    if (isEnablable(defaultControls)) {
      controls.addEventListener("dragging-changed", (event) => {
        defaultControls.enabled = !event.value;
      });
    }

    controls.addEventListener("objectChange", () => {
      onPositionChange([mesh.position.x, mesh.position.y, mesh.position.z]);
    });
    scene.add(controls);
    return () => {
      scene.remove(controls);
      controls.detach();
      controls.dispose();
    };
  }, [defaultCamera, gl, scene, mesh, controlsEnabled, defaultControls]);

  return (
    <mesh ref={refCallback} {...meshProps}>
      <ConstantSize scale={0.1}>
        <mesh>
          <sphereGeometry args={[0.1]} />
        </mesh>
      </ConstantSize>
    </mesh>
  );
}

type HtmlInputProps = GroupProps & {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value: number;
};

function ChangeRoll({ onChange, value, ...props }: HtmlInputProps) {
  const controls = useThree((state) => {
    if (state.controls instanceof OrbitControls) {
      return state.controls;
    }
    return null;
  });
  return (
    <group {...props}>
      <Html>
        <input
          className="ChangeRoll"
          onMouseDown={() => {
            if (!controls) {
              return;
            }
            controls.enabled = false;
          }}
          onMouseUp={() => {
            if (!controls) {
              return;
            }
            controls.enabled = true;
          }}
          type="range"
          step={Math.PI / 180}
          onChange={onChange}
          min={-Math.PI}
          max={Math.PI}
          value={value}
        />
      </Html>
    </group>
  );
}

type HtmlButtonProps = GroupProps & {
  onClick?: MouseEventHandler;
};

function AddControlPoint({ onClick, ...props }: HtmlButtonProps) {
  return (
    <group {...props}>
      <Html>
        <button className="AddControlPoint" onClick={onClick}>
          +
        </button>
      </Html>
    </group>
  );
}

function RemoveControlPoint({ onClick, ...props }: HtmlButtonProps) {
  return (
    <group {...props}>
      <Html>
        <button className="RemoveControlPoint" onClick={onClick}>
          -
        </button>
      </Html>
    </group>
  );
}

function ConstantSize({ scale, ...props }: GroupProps) {
  const camera = useThree((state) => state.camera);
  const ref = useRef<Group>(null!);
  const worldPosition = useMemo(() => new Vector3(), []);
  const worldPositionRef = useRef(worldPosition);

  useFrame(() => {
    if (!(camera instanceof PerspectiveCamera)) {
      throw new Error("ConstantSize only works for PerspectiveCamera");
    }
    ref.current.getWorldPosition(worldPositionRef.current);
    const factor =
      worldPositionRef.current.distanceTo(camera.position) *
      Math.min((1.9 * Math.tan((Math.PI * camera.fov) / 360)) / camera.zoom, 7);
    if (scale instanceof Vector3) {
      ref.current.scale.copy(scale);
    } else if (Array.isArray(scale)) {
      ref.current.scale.set(...scale);
    } else if (typeof scale === "number") {
      ref.current.scale.set(scale, scale, scale);
    } else {
      ref.current.scale.set(1, 1, 1);
    }

    ref.current.scale.multiplyScalar(factor);
  });

  return <group ref={ref} {...props} />;
}

export { SplineEditor };
