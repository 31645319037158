import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

import "virtual:vite-plugin-sentry/sentry-config";

if (window.browserEnv.SENTRY_DSN) {
  Sentry.init({
    dsn: window.browserEnv.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
