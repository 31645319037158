
     ;(() => {
       const matomoURL = window.browserEnv.MATOMO_URL
       const siteId = window.browserEnv.MATOMO_SITE_ID

       if (!matomoURL || !siteId) {
         return;
       }

       const _paq = window._paq = window._paq || [];

       _paq.push(["trackPageView"]);
       _paq.push(["enableLinkTracking"]);
       _paq.push(["setTrackerUrl", matomoURL+"/matomo.php"]);
       _paq.push(["setSiteId", siteId]);

       var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
       g.async=true; g.src=matomoURL+"/matomo.js"; s.parentNode.insertBefore(g,s);
     })();
    