import "./App.css";

import { Canvas } from "@react-three/fiber";

import {
  OrbitControls,
  PerspectiveCamera,
  TransformControls,
  TransformControlsProps,
} from "@react-three/drei";
import { TripleRail } from "./triple-rail";
import { SplineEditor } from "./spline-editor";
import { useState } from "react";
import { CatmullRomCurve3 } from "three";
import { ControlPoint } from "./types";

function App() {
  return (
    <div className="App">
      <Canvas className="Canvas">
        <Scene />
      </Canvas>
    </div>
  );
}

function Light(props: TransformControlsProps) {
  const [enabled, setEnabled] = useState(false);
  return (
    <TransformControls
      {...props}
      showX={enabled}
      showY={enabled}
      showZ={enabled}
    >
      <mesh onClick={() => setEnabled((v) => !v)}>
        <pointLight />
        <sphereGeometry />
      </mesh>
    </TransformControls>
  );
}

function Scene() {
  const [curve, setCurve] = useState<CatmullRomCurve3>();
  const [controlPoints, setControlPoints] = useState<ControlPoint[]>([]);

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 100, -5]} fov={45} />
      <OrbitControls makeDefault />
      <ambientLight intensity={0.5} />
      <Light position={[0, 10, 0]} />
      <SplineEditor
        onCurveChange={(newCurve) => setCurve(newCurve)}
        onControlPointsChange={(newControlPoints) =>
          setControlPoints(newControlPoints)
        }
      />
      <axesHelper args={[10]} />
      {curve && (
        <TripleRail debug curve={curve} controlPoints={controlPoints} />
      )}
      <mesh position={[0, -3, 0]} rotation={[(Math.PI / 2) * 3, 0, 0]}>
        <planeGeometry args={[1000, 1000, 1000]} />
        <meshStandardMaterial color="green" />
      </mesh>
    </>
  );
}

export default App;
